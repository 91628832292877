import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMenuEnabled: true,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setIsMenuEnabled: (state, action) => {
      state.isMenuEnabled = action.payload;
    },
  },
});

export const { setIsMenuEnabled } = menuSlice.actions;
export default menuSlice.reducer;
