import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../globals';

const PERMISSIONS_URL = `${API_DOMAIN}/vision-api/bapi-vision-user-permissions`;

const initialState = {
  permissions: null,
  isFetching: false,
};

export const UserPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    getPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    isFetching: (state, action) => {
      state.isFetching = action.payload;
    },
  },
});

export const { getPermissions, isFetching } = UserPermissionsSlice.actions;

export const fetchPermissions = (tokenId) => async (dispatch) => {
  dispatch(isFetching(true));
  try {
    const response = await axios.get(`${PERMISSIONS_URL}`, {
      headers: { Authorization: `Bearer ${tokenId}` },
    });
    dispatch(getPermissions(response.data));
  } catch (err) {
    console.log('err', err);
  }
  dispatch(isFetching(false));
};

export default UserPermissionsSlice.reducer;
