import { createSlice } from '@reduxjs/toolkit';

export const defaultFilters = {
  datetimeType: 'today',
  datetime: '',
  datetime2: '',
  service: [],
  type: [],
  errorIds: [],
  identifiers: '',
  context: '',
  id: '',
  user: '',
  bapiRequestId: '',
  dateCheck: '',
  failedMsgsCheck: false,
  resultsLength: '10',
  messageSent: '',
  messageReceived: '',
  groupRequestsCheck: true,
};
const initialState = {
  defaultFilters,
  filters: defaultFilters,
};

export const RequestsLoggerSlice = createSlice({
  name: 'requests-logger',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = RequestsLoggerSlice.actions;
export default RequestsLoggerSlice.reducer;
