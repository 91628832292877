import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'reactstrap';

const LinkAlertBar = ({ content, linkContent, linkTo }) => {
  return (
    <Alert
      style={{
        backgroundColor: '#1e88e5',
        color: 'white',
        padding: '10px',
        margin: '0 20px',
        textAlign: 'center',
      }}
    >
      {content}{' '}
      <a style={{ color: 'white' }} href={linkTo} target="_blank" rel="noreferrer">
        {' '}
        {linkContent}
      </a>
    </Alert>
  );
};

LinkAlertBar.propTypes = {
  content: PropTypes.string,
  linkContent: PropTypes.string,
  linkTo: PropTypes.string,
};

export default LinkAlertBar;
