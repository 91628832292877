import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../globals';

const GUEST_LOOKUP_URL = `${API_DOMAIN}/vision-api/profile-lookup`;

const initialState = {
  results: [],
  isFetching: false,
};

export const GuestLookupSlice = createSlice({
  name: 'guestLookup',
  initialState,
  reducers: {
    getGuests: (state, action) => {
      state.results = action.payload;
    },
    isFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    deleteResults: (state) => {
      state.results = [];
    },
  },
});

export const { getGuests, isFetching, deleteResults } = GuestLookupSlice.actions;

export const fetchGuestLookup =
  (tokenId, lastName, firstName, emailAddress, partyId) => async (dispatch) => {
    dispatch(isFetching(true));
    dispatch(deleteResults());
    try {
      const response = await axios.post(
        GUEST_LOOKUP_URL,
        {
          lastName,
          firstName,
          emailAddress,
          partyId,
        },
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        },
      );
      dispatch(getGuests(response.data));
    } catch (err) {
      console.log('err', err);
    }
    dispatch(isFetching(false));
  };

export default GuestLookupSlice.reducer;
