import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const AuthRequiredModal = ({ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }) => {
  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  return (
    <Modal toggle={closeModal} isOpen={authRequiredModalOpen}>
      <ModalHeader>Auth required</ModalHeader>
      <ModalBody>
        <p>Do you want to re-authenticate?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          No
        </Button>
        <Button color="primary" onClick={confirmModal}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AuthRequiredModal.propTypes = {
  authRequiredModalOpen: PropTypes.bool,
  setAuthRequiredModalOpen: PropTypes.func,
  triggerLogin: PropTypes.func,
};

export default AuthRequiredModal;
