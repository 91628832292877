import { createSlice } from '@reduxjs/toolkit';

export const productDefaultColumns = {
  SSV: false,
  addonsBeforeCheckout: false,
  aliceChatServiceId: false,
  aliceId: false,
  aliceKey: false,
  chatServiceId: false,
  country: false,
  heroImg: false,
  ideasId: false,
  kitchenCutCryptPassword: false,
  reservationEmailAddress: false,
  reservationEmailAddressPwd: false,
  reservationEmailUsername: false,
  shortName: false,
  silverwareCryptPassword: false,
  silverwareId: false,
  synxis: false,
  timezone: false,
  workdayId: false,
};

const capexProjectsDefaultColumns = {
  insertDate: false,
  updateDate: false,
  reference: false,
  sharePointURL: false,
  description: false,
};

const initialState = {
  products: productDefaultColumns,
  bookings: {},
  projects: capexProjectsDefaultColumns,
};

export const columnsVisibilitySlice = createSlice({
  name: 'columnsVisibility',
  initialState,
  reducers: {
    setVisibleColumns: (state, action) => {
      state[action.payload.name] = action.payload.columnVisibility;
    },
  },
});

export const { setVisibleColumns } = columnsVisibilitySlice.actions;
export default columnsVisibilitySlice.reducer;
