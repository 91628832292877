import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Error from '../../views/auth/Error';
import { fetchPermissions } from '../../store/user-permissions/UserPermissionsSlice';
import Loader from '../../layouts/loader/Loader';

const AuthGuard = (props) => {
  const { children } = props;
  const { authState, oktaAuth } = useOktaAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (authState && !init) {
      if (authState.isAuthenticated) {
        dispatch(fetchPermissions(authState.idToken.idToken)).then(() => setInit(true));
      } else {
        setInit(true);
      }
    }
  }, [authState]);

  if (authState === null || !init) {
    return <Loader />;
  }
  if (!authState.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
      return <Error />;
    }

    oktaAuth.signInWithRedirect({ originalUri: window.location.href });
  } else {
    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }
    return <>{children}</>;
  }
  return <></>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
