import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { useLocation, Link, useSearchParams } from 'react-router-dom';
//import SidebarData from '../sidebars/sidebardata/SidebarData';

const BreadCrumbs = ({ title }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const firstUrl = location.pathname.split('/')[1];
  const secondUrl = location.pathname.split('/')[2];
  const embeddedMode = searchParams.get('embedded_mode');

  // function to turn hyphen-case into title-case
  const titleCase = (str) => {
    return str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    !embeddedMode && (
      <div className="d-flex justify-content-between">
        <h4 className="text-capitalize">{title || (secondUrl ? `${secondUrl}` : `${firstUrl}`)}</h4>

        <Breadcrumb>
          <BreadcrumbItem to="/" tag={Link} className="text-decoration-none">
            Home
          </BreadcrumbItem>
          {firstUrl ? <BreadcrumbItem active>{titleCase(firstUrl)}</BreadcrumbItem> : ''}
          {secondUrl ? <BreadcrumbItem active>{titleCase(secondUrl)}</BreadcrumbItem> : ''}
        </Breadcrumb>
      </div>
    )
  );
};

BreadCrumbs.propTypes = {
  title: PropTypes.string,
};

export default BreadCrumbs;
