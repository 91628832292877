import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../globals';

const TRAINS_URL = `${API_DOMAIN}/vision-api/trains`;

const initialState = {
  list: [],
  isFetching: false,
};

export const TrainsSlice = createSlice({
  name: 'trains',
  initialState,
  reducers: {
    getTrains: (state, action) => {
      state.list = action.payload;
    },
    isFetching: (state, action) => {
      state.isFetching = action.payload;
    },
  },
});

export const { getTrains, isFetching } = TrainsSlice.actions;

export const fetchTrains = (tokenId) => async (dispatch) => {
  dispatch(isFetching(true));
  try {
    const response = await axios.get(TRAINS_URL, {
      headers: { Authorization: `Bearer ${tokenId}` },
    });
    dispatch(getTrains(response.data));
  } catch (err) {
    console.log('err', err);
  }
  dispatch(isFetching(false));
};

export default TrainsSlice.reducer;
