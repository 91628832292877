import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  miniSidebarActive: false,
  mobileSidebarOpen: false,
};

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    ToggleMiniSidebar: (state) => {
      state.miniSidebarActive = !state.miniSidebarActive;
    },
    ToggleMobileSidebar: (state) => {
      state.mobileSidebarOpen = !state.mobileSidebarOpen;
    },
  },
});

export const { ToggleMiniSidebar, ToggleMobileSidebar } = CustomizerSlice.actions;

export default CustomizerSlice.reducer;
