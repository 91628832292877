import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../globals';

const APIS_URL = `${API_DOMAIN}/vision-api/apis`;
const MERGE_URL = `${API_DOMAIN}/vision-api/merge`;

const initialState = {
  list: [],
  isFetching: false,
  isFetchingMerge: false,
  mergeResult: null,
};

export const ApiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    getApis: (state, action) => {
      state.list = action.payload;
    },
    isFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    isFetchingMerge: (state, action) => {
      state.isFetchingMerge = action.payload;
    },
    setMergeResult: (state, action) => {
      state.mergeResult = action.payload;
    },
  },
});

export const { getApis, isFetching, isFetchingMerge, setMergeResult } = ApiSlice.actions;

export const fetchApis = (tokenId) => async (dispatch) => {
  dispatch(isFetching(true));
  try {
    const response = await axios.get(APIS_URL, {
      headers: { Authorization: `Bearer ${tokenId}` },
    });
    dispatch(getApis(response.data));
  } catch (err) {
    console.log('err', err);
  }
  dispatch(isFetching(false));
};

export const fetchMerge = (tokenId, startDate, endDate, endpoint) => async (dispatch) => {
  dispatch(isFetchingMerge(true));
  dispatch(setMergeResult(null));
  try {
    await axios.post(
      MERGE_URL,
      {
        startDate,
        endDate,
        endpoint,
      },
      {
        headers: { Authorization: `Bearer ${tokenId}` },
      },
    );
    dispatch(setMergeResult(true));
  } catch (err) {
    dispatch(setMergeResult(false));
  }
  dispatch(isFetchingMerge(false));
};

export default ApiSlice.reducer;
