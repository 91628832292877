import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../globals';

const PROFILE_URL = `${API_DOMAIN}/vision-api/profile?id=:osc_id`;
const BOOKINGS_URL = `${API_DOMAIN}/vision-api/bookings?id=:osc_id`;
const REVIEWS_URL = `${API_DOMAIN}/vision-api/responses?id=:osc_id`;

const initialState = {
  profile: {},
  bookings: [],
  reviews: [],
  isFetchingGuest: false,
  isFetchingBookings: false,
  isFetchingReviews: false,
};

export const GuestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    getGuest: (state, action) => {
      state.profile = action.payload;
    },
    isFetchingGuest: (state, action) => {
      state.isFetchingGuest = action.payload;
    },
    isFetchingBookings: (state, action) => {
      state.isFetchingBookings = action.payload;
    },
    getBookings: (state, action) => {
      state.bookings = action.payload;
    },
    isFetchingReviews: (state, action) => {
      state.isFetchingReviews = action.payload;
    },
    getReviews: (state, action) => {
      state.reviews = action.payload;
    },
  },
});

export const {
  getGuest,
  getBookings,
  getReviews,
  isFetchingBookings,
  isFetchingGuest,
  isFetchingReviews,
} = GuestSlice.actions;

export const fetchGuest = (tokenId, oscId) => async (dispatch) => {
  dispatch(isFetchingGuest(true));
  try {
    const response = await axios.post(`${PROFILE_URL.replace(':osc_id', oscId)}`, null, {
      headers: { Authorization: `Bearer ${tokenId}` },
    });
    dispatch(getGuest(response.data));
  } catch (err) {
    console.log('err', err);
  }
  dispatch(isFetchingGuest(false));
};

export const fetchBookings = (tokenId, oscId) => async (dispatch) => {
  dispatch(isFetchingBookings(true));
  try {
    const response = await axios.post(`${BOOKINGS_URL.replace(':osc_id', oscId)}`, null, {
      headers: { Authorization: `Bearer ${tokenId}` },
    });
    dispatch(getBookings(response.data));
  } catch (err) {
    console.log('err', err);
  }
  dispatch(isFetchingBookings(false));
};

export const fetchReviews = (tokenId, oscId) => async (dispatch) => {
  dispatch(isFetchingReviews(true));
  try {
    const response = await axios.post(`${REVIEWS_URL.replace(':osc_id', oscId)}`, null, {
      headers: { Authorization: `Bearer ${tokenId}` },
    });
    dispatch(getReviews(response.data));
  } catch (err) {
    console.log('err', err);
  }
  dispatch(isFetchingReviews(false));
};

export default GuestSlice.reducer;
