import { OktaAuth } from '@okta/okta-auth-js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import OktaAuthContext from './context';
import { authConfig } from '../../globals';

export const AuthProvider = ({ children }) => {
  const [oktaAuth, setOktaAuth] = useState(null);

  useEffect(() => {
    const oktaAuthInstance = new OktaAuth(authConfig);
    setOktaAuth(oktaAuthInstance);
  }, []);

  return <OktaAuthContext.Provider value={oktaAuth}>{children}</OktaAuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
