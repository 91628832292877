import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const Message = memo(({ open, message, color }) => (
  <Alert color={color} isOpen={open}>
    {message}
  </Alert>
));

export default function useMessage() {
  const [shown, setShown] = useState(false);
  const [props, setProps] = useState({});
  let timeout = null;

  const RenderedMessage = useCallback(() => {
    return <Message open={shown} color={props.color} message={props.message} />;
  }, [shown, props.color, props.message]);

  const showMessage = useCallback((message, color = 'warning', fixed = false, ms = 6000) => {
    setProps({ message, color });
    setShown(true);
    if (!fixed) {
      timeout = setTimeout(() => setShown(false), ms);
    }
  }, []);

  const hideMessage = useCallback(() => {
    clearTimeout(timeout);
    setShown(false);
  }, []);

  return [RenderedMessage, showMessage, hideMessage];
}

Message.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  color: PropTypes.string,
};
