import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featureFlags: [],
};

export const MetadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setMetadata: (state, action) => {
      state.featureFlags = action.payload.featureFlags;
    },
  },
});

export const { setMetadata } = MetadataSlice.actions;
export default MetadataSlice.reducer;
