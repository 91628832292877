import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { API_DOMAIN } from '../../globals';

const PROPERTIES_URL = `${API_DOMAIN}/vision-api/properties`;
const ALICE_API_KEY_URL = `${API_DOMAIN}/vision-api/alice-api-key`;
const GROUP_STAY_REVENUE_URL = `${API_DOMAIN}/vision-api/group-booking-stay-revenue-by-hotel`;

const initialState = {
  list: [],
  isFetching: false,
  isUpdatingAliceApiKey: false,
  aliceKeyUpdatingFailed: false,
  aliceKeyUpdatingSuccess: false,
  isFetchingGroupStayRevenue: false,
  groupStayRevenueResult: null,
};

export const PropertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    getProperties: (state, action) => {
      state.list = action.payload;
    },
    isFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setAliceKeyUpdatingFailed: (state, action) => {
      state.aliceKeyUpdatingFailed = action.payload;
    },
    setAliceKeyUpdatingSuccess: (state, action) => {
      state.aliceKeyUpdatingSuccess = action.payload;
    },
    isUpdatingAliceApiKey: (state, action) => {
      state.isUpdatingAliceApiKey = action.payload;
    },
    setAliceApiKey: (state, action) => {
      state.list = state.list.map((p) => {
        if (p.code === action.payload) {
          return { ...p, hasAliceApiKey: true };
        }
        return p;
      });
    },
    isFetchingGroupStayRevenue: (state, action) => {
      state.isFetchingGroupStayRevenue = action.payload;
    },
    setGroupStayRevenueResult: (state, action) => {
      state.groupStayRevenueResult = action.payload;
    },
  },
});

export const {
  getProperties,
  isFetching,
  isUpdatingAliceApiKey,
  setAliceApiKey,
  setAliceKeyUpdatingFailed,
  setAliceKeyUpdatingSuccess,
  setGroupStayRevenueResult,
  isFetchingGroupStayRevenue,
} = PropertiesSlice.actions;

export const fetchProperties =
  (tokenId, onlyWithTenant = true, type) =>
  async (dispatch) => {
    dispatch(isFetching(true));
    try {
      const params = { onlyWithTenant: onlyWithTenant ? '1' : '0' };
      if (type) {
        params.type = type;
      }

      const response = await axios.get(PROPERTIES_URL, {
        headers: { Authorization: `Bearer ${tokenId}` },
        params,
      });

      dispatch(getProperties(response.data));
    } catch (err) {
      console.log('err', err);
    }
    dispatch(isFetching(false));
  };

export const updateAliceApiKey =
  (tokenId, propertyCode, apiKey, alicePropertyId) => async (dispatch) => {
    dispatch(isUpdatingAliceApiKey(true));
    dispatch(setAliceKeyUpdatingSuccess(false));
    dispatch(setAliceKeyUpdatingFailed(false));
    try {
      await axios.post(
        ALICE_API_KEY_URL,
        {
          propertyCode,
          apiKey,
          alicePropertyId,
        },
        { headers: { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${tokenId}` } },
      );
      dispatch(setAliceApiKey(propertyCode));
      dispatch(setAliceKeyUpdatingFailed(false));
      dispatch(setAliceKeyUpdatingSuccess(true));
    } catch (err) {
      dispatch(setAliceKeyUpdatingFailed(true));
    }
    dispatch(isUpdatingAliceApiKey(false));
  };

export const fetchGroupStayRevenue =
  (tokenId, hotelCode, startDate = null, endDate = null, groupIds = null) =>
  async (dispatch) => {
    dispatch(isFetchingGroupStayRevenue(true));
    dispatch(setGroupStayRevenueResult(null));
    try {
      await axios.post(
        GROUP_STAY_REVENUE_URL,
        {
          hotelCode,
          startDate,
          endDate,
          groupIds,
        },
        {
          headers: { Authorization: `Bearer ${tokenId}` },
        },
      );
      dispatch(setGroupStayRevenueResult(true));
    } catch (err) {
      dispatch(setGroupStayRevenueResult(false));
    }
    dispatch(isFetchingGroupStayRevenue(false));
  };

export default PropertiesSlice.reducer;
