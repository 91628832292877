import React from 'react';
import PropTypes from 'prop-types';
import { useExpanded, useFlexLayout, useResizeColumns, useTable } from 'react-table';
import '../requests-logger/style.css';
import moment from 'moment';

const EmailsTable = ({ emails, onClickEmail }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Emails',
        columns: [
          {
            Header: 'From',
            accessor: 'fromAddress',
            width: 150,
          },
          {
            Header: 'To',
            accessor: 'toAddress',
            width: 150,
          },
          {
            Header: 'Subject',
            accessor: 'subject',
            width: 150,
          },

          {
            Header: 'Case Number',
            accessor: 'case.caseNumber',
            width: 150,
          },
          {
            Header: 'Contact Name',
            accessor: 'case.account.name',
            width: 150,
          },
          {
            Header: 'Date Created',
            accessor: 'createdDate',
            width: 150,
            Cell: ({ value }) => moment(value).format('MMMM Do YYYY, h:mm a'),
          },
        ],
      },
    ],
    [emails],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: emails },
    useExpanded,
    useFlexLayout,
    useResizeColumns,
  );

  return (
    <div className="react-table">
      <div {...getTableProps()} className="table">
        <div className="thead">
          <div className="tr -header" {...headerGroups[1].getHeaderGroupProps()}>
            {headerGroups[1].headers.map((column) => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
                <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                />
              </div>
            ))}
          </div>
        </div>
        <div {...getTableBodyProps()} className="tbody">
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <div
                className={`tr ${
                  !row.id.includes('.') ? (i % 2 === 0 ? '-odd' : '-even') : '-children'
                }`}
                {...row.getRowProps()}
                onClick={() => onClickEmail(row.original.id)}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  return (
                    <div {...cellProps} className="td">
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

EmailsTable.propTypes = {
  emails: PropTypes.array.isRequired,
  onClickEmail: PropTypes.func.isRequired,
};

export default EmailsTable;
